<template>
    <div>
        <div class="step-content">
            <div class="step-form-item">
                <div class="step-form-item-label">发送对象</div>
                <div>
                    <div>
                        <label
                            for=""
                            v-for="(obj, key) in sendObjTypeStr"
                            :key="key"
                            @click="changeSendObjType(key)"
                        >
                            <input
                                type="radio"
                                :value="key"
                                v-model="checkedSendObjType"
                            />
                            {{ obj }}
                        </label>
                    </div>
                    <div>
                        <label
                            for=""
                            v-for="item in questionnaireSendTypeMapping[
                                checkedSendObjType
                            ]"
                            :key="item.id"
                        >
                            <input
                                type="checkbox"
                                :value="item.id"
                                v-model="sendType"
                            />
                            {{ item.name }}
                        </label>
                    </div>
                </div>
            </div>
            <div class="step-form-item">
                <div class="step-form-item-label">发送条件</div>
                <div>
                    <div>
                        <label for="">
                            <input
                                type="radio"
                                v-model="haveCondition"
                                :value="1"
                            />
                            有
                        </label>
                        <label for="">
                            <input
                                type="radio"
                                v-model="haveCondition"
                                :value="0"
                            />
                            无（立即发送）
                        </label>
                    </div>
                    <template v-if="haveCondition">
                        <div>
                            <label
                                for=""
                                v-if="+checkedSendObjType === SEND_OBJ_TYPE.USER"
                            >
                                <input
                                    type="radio"
                                    v-model="type"
                                    :value="
                                        TRIGGER_SEND_QUESTIONNAIRE_TYPE.DEED
                                    "
                                />
                                行为
                            </label>
                            <label for="">
                                <input
                                    type="radio"
                                    v-model="type"
                                    :value="
                                        TRIGGER_SEND_QUESTIONNAIRE_TYPE.TIMING
                                    "
                                />
                                定时发送
                            </label>
                            <label for="">
                                <input
                                    type="radio"
                                    v-model="type"
                                    :value="
                                        TRIGGER_SEND_QUESTIONNAIRE_TYPE.REGISTER_DATE
                                    "
                                />
                                按注册时间发送
                            </label>
                            <label
                                for=""
                                v-if="
                                    ![
                                        SEND_OBJ_TYPE.USER,
                                        SEND_OBJ_TYPE.PROPERTY,
                                    ].includes(Number(checkedSendObjType))
                                "
                            >
                                <input
                                    type="radio"
                                    v-model="type"
                                    :value="
                                        TRIGGER_SEND_QUESTIONNAIRE_TYPE.SIGN_CONTRACT_DATE
                                    "
                                />
                                按合同签约时间发送
                            </label>
                        </div>
                        <template
                            v-if="type === TRIGGER_SEND_QUESTIONNAIRE_TYPE.DEED && +checkedSendObjType === SEND_OBJ_TYPE.USER"
                        >
                            <div style="margin-bottom: 20px; margin-top: 15px">
                                <div class="cs-select" style="width: 286px">
                                    <select
                                        :style="{
                                            width: '80%',
                                            color:
                                                condition.deedType != ''
                                                    ? '#444'
                                                    : '#999',
                                        }"
                                        v-model="condition.deedType"
                                    >
                                        <option value="">请选择</option>
                                        <option :value="1">
                                            购买x次商品且已签收
                                        </option>
                                        <option :value="2">
                                            购买x次上门服务且已核销消费码
                                        </option>
                                        <option :value="3">
                                            已签约x次企业服务
                                        </option>
                                        <option :value="4">
                                            已使用x次共享会议室
                                        </option>
                                    </select>
                                    <i class="icon el-icon-caret-bottom"></i>
                                </div>
                                <span style="color: #999">
                                    【满足行为，次日上午08:00发送问卷】
                                </span>
                            </div>
                        </template>
                        <template
                            v-if="
                                type === TRIGGER_SEND_QUESTIONNAIRE_TYPE.TIMING
                            "
                        >
                            <div style="margin-bottom: 20px" class="cs-select">
                                <el-date-picker
                                    v-model="condition.sendDate"
                                    type="datetime"
                                    value-format="yyyy-MM-dd HH:mm"
                                    format="yyyy-MM-dd HH:mm"
                                    placeholder="请填写时间"
                                    :editable="false"
                                ></el-date-picker>
                                <i class="icon el-icon-caret-bottom"></i>
                            </div>
                        </template>
                        <template
                            v-if="
                                [
                                    TRIGGER_SEND_QUESTIONNAIRE_TYPE.REGISTER_DATE,
                                    TRIGGER_SEND_QUESTIONNAIRE_TYPE.SIGN_CONTRACT_DATE,
                                ].includes(type)
                            "
                        >
                            <div
                                class="align-center"
                                style="margin-bottom: 20px"
                            >
                                {{
                                    TRIGGER_SEND_QUESTIONNAIRE_TYPE.REGISTER_DATE ===
                                    type
                                        ? "注册"
                                        : "签约"
                                }}满
                                <input
                                    type="text"
                                    style="width: 286px"
                                    class="cs-input ml-2 mr-2"
                                    value=""
                                    placeholder="请输入"
                                    v-model="condition.day"
                                />
                                天
                                <span style="color: #999">
                                    【满足天数，次日上午08:00发送问卷】
                                </span>
                            </div>
                        </template>

                        <div
                            v-if="type === TRIGGER_SEND_QUESTIONNAIRE_TYPE.DEED && +checkedSendObjType === SEND_OBJ_TYPE.USER"
                        >
                            <div class="cs-select" style="width: 196px">
                                <select
                                    v-model="condition.frequency"
                                    :style="{
                                        color:
                                            condition.frequency != ''
                                                ? '#444'
                                                : '#999',
                                    }"
                                >
                                    <option value="">请选择</option>
                                    <option v-for="n in 20" :key="n" :value="n">
                                        {{ n }}
                                    </option>
                                </select>
                                <i class="icon el-icon-caret-bottom"></i>
                            </div>
                            次
                        </div>
                    </template>
                </div>
            </div>
            <div class="step-form-item">
                <div class="step-form-item-label">有效期</div>
                <div>
                    <div class="cs-select" style="width: 196px">
                        <select
                            v-model="validDays"
                            :style="{
                                color: validDays != '' ? '#444' : '#999',
                            }"
                        >
                            <option value="">请选择</option>
                            <option v-for="n in 10" :key="n" :value="n">
                                {{ n }}
                            </option>
                        </select>
                        <i class="icon el-icon-caret-bottom"></i>
                    </div>
                    天
                </div>
            </div>
            <div v-if="haveCondition === 0 && Number(checkedSendObjType) !== 2">
            <div class="step-form-item">
                    <div class="step-form-item-label">新注册用户发送</div>
                    <div>
                        <div>
                            <label for="">
                                <input
                                        type="radio"
                                        v-model="newUserRegister"
                                        :value="1"
                                />
                                发送
                            </label>
                            <label for="">
                                <input
                                        type="radio"
                                        v-model="newUserRegister"
                                        :value="2"
                                />
                               不发送
                            </label>
                        </div>
                    </div>
                </div>
                <div v-if="newUserRegister === 1">
                    <div class="step-form-item">
                        <div class="step-form-item-label"></div>
                        <div>
                            <span>停止发送时间</span>
                            <CSSelect
                                    style="margin-left: 10px; width: 280px; margin-right: 20px;"
                            >
                                <el-date-picker
                                        v-model="endTime"
                                        type="datetime"
                                        value-format="yyyy-MM-dd HH:mm"
                                        placeholder="请选择"
                                        format="yyyy-MM-dd HH:mm"
                                        :editable="false"
                                        prefix-icon="el-icon-time"
                                ></el-date-picker>
                            </CSSelect>
                        </div>
                    </div>
                    <div class="step-form-item">
                        <div class="step-form-item-label"></div>
                        <div style="margin-top: -18px;margin-bottom:10px">
                            <svg
                                    class="icon"
                                    aria-hidden="true"
                                    style="font-size: 20px; margin-right: 10px"
                            >
                                <use xlink:href="#icon-menua-zu92"></use>
                            </svg>

                            <span style="font-size: 20px;color: #999999"
                            >选择发送后，系统会在所选“停止发送时间”之前向新注册的用户发送此问卷。</span
                            >
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <slot name="footer"></slot>
    </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import {
    QUESTIONNAIRE_SEND_TYPE,
    QUESTIONNAIRE_SEND_TYPE_STR,
    TRIGGER_SEND_QUESTIONNAIRE_TYPE,
} from "@/constant";

//  发送对象
const SEND_OBJ_TYPE = {
    // 用户
    USER: 1,
    // 物业员工
    PROPERTY: 2,
    // 租户业主
    OWNER: 3,
};

export default {
    name: "addQuestionnaireFirstStep",
    components: {
        CSSelect,
    },
    data() {
        return {
            SEND_OBJ_TYPE,
            sendObjTypeStr: {
                [SEND_OBJ_TYPE.USER]: "C端用户",
                [SEND_OBJ_TYPE.PROPERTY]: "物业员工",
                [SEND_OBJ_TYPE.OWNER]: "租户",
            },
            QUESTIONNAIRE_SEND_TYPE,
            // 参与问卷调查的人员
            questionnaireSendTypeMapping: {
                [SEND_OBJ_TYPE.USER]: [
                    {
                        id: QUESTIONNAIRE_SEND_TYPE.USER,
                        name:
                            QUESTIONNAIRE_SEND_TYPE_STR[
                                QUESTIONNAIRE_SEND_TYPE.USER
                            ],
                    },
                    {
                        id: QUESTIONNAIRE_SEND_TYPE.VISITER,
                        name:
                            QUESTIONNAIRE_SEND_TYPE_STR[
                                QUESTIONNAIRE_SEND_TYPE.VISITER
                            ],
                    },
                ],
                [SEND_OBJ_TYPE.PROPERTY]: [
                    {
                        id: QUESTIONNAIRE_SEND_TYPE.EMPLOYEE,
                        name:
                            QUESTIONNAIRE_SEND_TYPE_STR[
                                QUESTIONNAIRE_SEND_TYPE.EMPLOYEE
                            ],
                    },
                    {
                        id: QUESTIONNAIRE_SEND_TYPE.DEPARTMENT_MANAGER,
                        name:
                            QUESTIONNAIRE_SEND_TYPE_STR[
                                QUESTIONNAIRE_SEND_TYPE.DEPARTMENT_MANAGER
                            ],
                    },
                    {
                        id: QUESTIONNAIRE_SEND_TYPE.PROPERTY_MANAGER,
                        name:
                            QUESTIONNAIRE_SEND_TYPE_STR[
                                QUESTIONNAIRE_SEND_TYPE.PROPERTY_MANAGER
                            ],
                    },
                ],
                [SEND_OBJ_TYPE.OWNER]: [
                    {
                        id: QUESTIONNAIRE_SEND_TYPE.ENTERPRISE_DOCKING_PERSON,
                        name:
                            QUESTIONNAIRE_SEND_TYPE_STR[
                                QUESTIONNAIRE_SEND_TYPE
                                    .ENTERPRISE_DOCKING_PERSON
                            ],
                    },
                    /* {
                        id: QUESTIONNAIRE_SEND_TYPE.OWNER,
                        name:
                            QUESTIONNAIRE_SEND_TYPE_STR[
                                QUESTIONNAIRE_SEND_TYPE.OWNER
                            ],
                    }, */
                ],
            },
            checkedSendObjType: SEND_OBJ_TYPE.USER,
            TRIGGER_SEND_QUESTIONNAIRE_TYPE,
            // 是否发送条件
            haveCondition: 1,

            sendType: [],
            condition: {
                deedType: "",
                frequency: "",
                sendDate: "",
                day: "",
            },
            type: 1,
            validDays: "",
            //新用户注册发送
            newUserRegister:2,
            endTime:""
        };
    },
    methods: {
        /**
         * 切换发送的对象
         * @param {Number} type 对象类型 参考：SEND_OBJ_TYPE
         *  */
        changeSendObjType(type) {
            this.checkedSendObjType = type;
            this.sendType = [];
        },
        // 返回data中的数据
        returnDataObj() {
            let { validDays, type, sendType, condition: tempCondition,newUserRegister,endTime } = this;
            let condition = { ...tempCondition };
            switch (type) {
                case TRIGGER_SEND_QUESTIONNAIRE_TYPE.DEED:
                    delete condition.sendDate;
                    delete condition.day;
                    break;
                case TRIGGER_SEND_QUESTIONNAIRE_TYPE.TIMING:
                    delete condition.day;
                    delete condition.deedType;
                    delete condition.frequency;
                    break;
                case TRIGGER_SEND_QUESTIONNAIRE_TYPE.REGISTER_DATE:
                    delete condition.sendDate;
                    delete condition.deedType;
                    delete condition.frequency;
                    break;
                case TRIGGER_SEND_QUESTIONNAIRE_TYPE.SIGN_CONTRACT_DATE:
                    delete condition.sendDate;
                    delete condition.deedType;
                    delete condition.frequency;
                    break;
            }

            condition = this.$vc.removeNullProperty(condition);
            if (this.haveCondition === 0) {
                type = 0;
                condition = "";
            }
            return {
                validDays,
                type,
                sendType,
                condition,
                newUserRegister,
                endTime
            };
        },
    },
};
</script>

<style lang="stylus" scoped>
@import './questionnaireStep.styl'
    .step-form-item-label
        width 170px !important
</style>
